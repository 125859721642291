import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
// import { ToastContainer } from 'react-toastify';

// import { AnyConnectedUser, AdminOnly, ParentOnly } from 'components/utils/authorization';

import './appstyle.css';

import NavBar from 'components/nav/navbar';

import { AuthProvider } from 'contexts/auth';
import { ConfigProvider } from 'contexts/config';

import Accountant from 'components/accountant';
import Admin from 'components/admin';
import Calendar from 'components/calendar';
import Documents from 'components/documents';
import Enrolment from 'components/enrolment';
import Home from 'components/home';
import Parent from 'components/parent';
import Prof from 'components/prof';
import Rates from 'components/rates';
import UserProfile from 'components/user/profile';
import UserSignin from 'components/user/signin';

// const ProtectedAdmin = AdminOnly(Admin);
// const ProtectedParent = ParentOnly(Parent);
// const ProtectedUserProfile = AnyConnectedUser(UserProfile);

const App = () => (
	<AuthProvider>
		<ConfigProvider>
			<NavBar />
			<main className="container-fluid" style={{ marginTop: '5rem' }}>
				<Route exact path="/" component={ Home } />
				<Route exact path="/user/signin" component={ UserSignin } />
				<Route exact path="/user/profile" component={ UserProfile } />
				<Route exact path="/rates" component={ Rates } />
				<Route exact path="/documents" component={ Documents } />
				<Route path="/enrolment" component={ Enrolment } />
				<Route path="/calendar" component={ Calendar } />
				<Route path="/admin" component={ Admin } />
				<Route path="/parent" component={ Parent } />
				<Route path="/prof" component={ Prof } />
				<Route path="/accountant" component={ Accountant } />
			</main>
			<footer>
				<Row>
					<Col>
						<p className="text-right">
							<img src="logo_playgroup.png" alt="Logo Playgroup" height="50"/>
						</p>
					</Col>
					<Col>
						<p className="text-center"> PLAYGROUP · 13, rue Schulmeister · 67100 Strasbourg · France </p>
						<p className="text-center"> Tél. +33 (0) 3 88 39 10 47 </p>
					</Col>
					<Col>
						<p>
							<img src="logo_UE_FEDER.png" alt="Logo UE FEDER" height="50"/>
						</p>
					</Col>
				</Row>
			</footer>
			{/* <ToastContainer position="top-center" /> */}
		</ConfigProvider>
	</AuthProvider>
);

export default withRouter(App);

import React from 'react';
import { Alert } from 'reactstrap';

const Home = (props) => (
	<React.Fragment>
		<div className="jumbotron">
			<h1> Bienvenue au Playgroup ! </h1>
		</div>

		<Alert color="info">
			<h4 className='text-center alert-heading'>Vous pouvez visiter notre nouveau site web <a href="https://playgroup.fr/wordpress/">ici</a>.</h4>
		</Alert>

		<Alert color="info">

            <p>Vous recherchez <strong>un environnement d'apprentissage bilingue français-anglais</strong> ?<br/>
			Merci de remplir le formulaire en cliquant <a href="https://forms.gle/5rUxWQ6YFHP4usEB9">ici</a>.</p>


			<hr />
  			
            <p>Are you interested in <strong>Montessory pedagogy</strong> ?<br/>
			To ask for a place, please fill out the form by clicking <a href="https://forms.gle/5rUxWQ6YFHP4usEB9">here</a>.<br/>
			</p>

        </Alert>

		<p>
			En 1976, des familles anglophones fondent une association culturelle à but non lucratif « Le cercle international » et le PLAYGROUP,
			un jardin d’enfants bilingue franco-anglais à pédagogie active voit le jour.
		</p>

		<p>
			Depuis, le PLAYGROUP accueille les enfants de 30 mois à 6 ans dans un contexte unique, une mixité entre anglophones et francophones
			avec la familiarisation à la langue et à la culture de chacun, un taux d’encadrement exceptionnel
			(1 professionnel par groupe de 12 enfants le matin et 1 pour 15 l’après-midi), de nombreuses sorties organisées
			(éveil à la gymnastique, médiathèque, ludothèque, théâtre, concerts, expositions …), un accueil périscolaire.
		</p>
		
		<p>
			Dans chaque classe, deux enseignants l’un francophone, l’autre anglophone exercent leur activité éducative et pédagogique dans leur
			langue maternelle. L’enfant évolue dans un environnement bilingue qui lui permet de s’épanouir à son rythme en fonction de son développement,
			de s’initier à une langue et à une culture étrangère, d’acquérir des connaissances nécessaires à sa future intégration dans le système scolaire.
		</p>
		
		<p>
			Les P’tits Loups, structure périscolaire, accueille les enfants après l’école, les mercredis et pendant les vacances.
			Des programmes à thèmes sont proposés en plus des sorties régulières.
		</p>
		
		<p>
			La participation des familles au fonctionnement de l’association et du PLAYGROUP conforte une ambiance familiale propice à l’éveil et l’épanouissement des enfants.
		</p>
		
		<hr />
		
		<p>
			Les jours d'ouverture, le Playgroup est ouvert entre 7h45 et 18h15.
		</p>
	</React.Fragment>
);

export default Home;



import React from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import {
	Table, Button, Badge, UncontrolledTooltip
} from 'reactstrap';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfMonth from 'date-fns/start_of_month';
import addMonths from 'date-fns/add_months';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import compareDesc from 'date-fns/compare_desc';

import { saveAs } from 'file-saver';

import ConfirmationModal from 'components/utils/confirmation_modal';

import ParentChildDetailRegularContract from 'components/parent/child/contracts/regular';
import ParentChildDetailPunctualContract from 'components/parent/child/contracts/punctual';
import ParentChildDetailHolidayContract from 'components/parent/child/contracts/holiday';

import { fetchSchoolYears } from 'actions/school_year';
import {
	fetchRegularContractsOfChild,
	fetchPunctualContractsOfChild,
	fetchHolidayContractsOfChild
} from 'actions/child';
import { fetchRegularContractInvoice } from 'actions/regular_contract_invoice';
import { removeRegularContract } from 'actions/regular_contract';
import { fetchPunctualContractInvoice } from 'actions/punctual_contract_invoice';
import { removePunctualContract } from 'actions/punctual_contract';
import { fetchHolidayContractInvoice } from 'actions/holiday_contract_invoice';
import { removeHolidayContract } from 'actions/holiday_contract';
import {monthInvoiceFinder} from "../../../utils/month_invoice_finder";

class ParentChildDetailContracts extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		regularContracts: [],
		punctualContracts: [],
		holidayContracts: [],
		removeRegularContractId: 0,
		removeRegularContractModalOpen: false,
		removePunctualContractId: 0,
		removePunctualContractModalOpen: false,
		removeHolidayContractId: 0,
		removeHolidayContractModalOpen: false
	};

	downloadRegularContractInvoice = (regularContractInvoiceId, filename) => {
		return fetchRegularContractInvoice(regularContractInvoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	removeRegularContract = (regularContractId) => {
		return removeRegularContract(regularContractId)
		.then(() => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.filter((c) => c.id !== regularContractId)
			}));
		});
	};

	downloadPunctualContractInvoice = (punctualContractInvoiceId, filename) => {
		return fetchPunctualContractInvoice(punctualContractInvoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	removePunctualContract = (punctualContractId) => {
		return removePunctualContract(punctualContractId)
		.then(() => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.filter((c) => c.id !== punctualContractId)
			}));
		});
	};

	downloadHolidayContractInvoice = (holidayContractInvoiceId, filename) => {
		return fetchHolidayContractInvoice(holidayContractInvoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	removeHolidayContract = (holidayContractId) => {
		return removeHolidayContract(holidayContractId)
		.then(() => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.filter((c) => c.id !== holidayContractId)
			}));
		});
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYears(),
			fetchRegularContractsOfChild(this.props.child.id),
			fetchPunctualContractsOfChild(this.props.child.id),
			fetchHolidayContractsOfChild(this.props.child.id)
		])
		.then(([ schoolYears, regularContracts, punctualContracts, holidayContracts ]) => {
			const punctualContractsWithStartDate = punctualContracts.map((pc) => ({
				...pc,
				startDate: pc.PunctualOffers.length > 0 ?
					pc.PunctualOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						pc.PunctualOffers[0].Day.date
					) :
					new Date()
			}));
			const holidayContractsWithStartDate = holidayContracts.map((hc) => ({
				...hc,
				startDate: hc.HolidayOffers.length > 0 ?
					hc.HolidayOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						hc.HolidayOffers[0].Day.date
					) :
					new Date()
			}));
			this.setState({
				schoolYears,
				regularContracts: regularContracts.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				punctualContracts: punctualContractsWithStartDate.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				holidayContracts: holidayContractsWithStartDate.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Route path={ this.props.match.path + '/regular/:contractId' } render={ (props) => {
				const contract = this.state.regularContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<ParentChildDetailRegularContract
						regularContract={ contract }
						downloadRegularContractInvoice={ (invoiceId, filename) => this.downloadRegularContractInvoice(invoiceId, filename) }
					/>
				);
			} } />
			<Route path={ this.props.match.path + '/punctual/:contractId' } render={ (props) => {
				const contract = this.state.punctualContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<ParentChildDetailPunctualContract
						punctualContract={ contract }
						downloadPunctualContractInvoice={ (invoiceId, filename) => this.downloadPunctualContractInvoice(invoiceId, filename) }
					/>
				);
			} } />
			<Route path={ this.props.match.path + '/holiday/:contractId' } render={ (props) => {
				const contract = this.state.holidayContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<ParentChildDetailHolidayContract
						holidayContract={ contract }
						downloadHolidayContractInvoice={ (invoiceId, filename) => this.downloadHolidayContractInvoice(invoiceId, filename) }
					/>
				);
			} } />
			<Route path={ this.props.match.url } exact render={ () => (
				<React.Fragment>
				<h3> <i className="fa fa-clock-o"></i> Regular contracts </h3>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoices status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.regularContracts.map((regularContract) => {
						const contractInvoices = [];
						let d = startOfMonth(regularContract.startDate);
						const end = lastDayOfMonth(regularContract.endDate);
						while (isBefore(d, end)) {
							contractInvoices.push({
								year: d.getFullYear(),
								month: d.getMonth(),
								invoice: regularContract.RegularContractInvoices.find(monthInvoiceFinder(d))
							});
							d = addMonths(d, 1);
						}
						const schoolYear = this.state.schoolYears.find((s) => s.id === regularContract.SchoolYearId);

						return (
							<tr key={ regularContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ format(regularContract.startDate, 'DD-MM-YYYY') }</td>
								<td>{ format(regularContract.endDate, 'DD-MM-YYYY') }</td>
								<td>{ format(regularContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ regularContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{ contractInvoices.map((contractInvoice, idx) => {
										const color =
											contractInvoice.invoice && contractInvoice.invoice.status === 'edited' ? '#FF8000' :
											contractInvoice.invoice && contractInvoice.invoice.status === 'sent' ? '#DDDD00' :
											contractInvoice.invoice && contractInvoice.invoice.status === 'paid' ? '#66CC00' :
											'#CC0000';
										const invoiceid = 'invoice_'+regularContract.id+'_'+contractInvoice.year+'_'+contractInvoice.month;
										return (
											<span key={ idx }>
												<Badge style={{ backgroundColor: color }} id={ invoiceid }>
													{ contractInvoice.month + 1 }
												</Badge>
												{ contractInvoice.invoice
												&& <UncontrolledTooltip placement="top" delay={{ show: 0, hide: 100 }} autohide={ false } target={ invoiceid }>
													<Button color="info" size="sm" style={{ cursor: 'pointer' }}
														onClick={ () => this.downloadRegularContractInvoice(contractInvoice.invoice.id, contractInvoice.invoice.filename) }
													>
														<i className="fa fa-download"></i>
													</Button>
												</UncontrolledTooltip> }
											</span>
										);
									}) }
								</td>
								<td className="text-right">
									{ regularContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeRegularContractId: regularContract.id, removeRegularContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>{' '}Delete
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/regular/' + regularContract.id }>
										<i className="fa fa-search"></i>{' '}Detail
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removeRegularContractModalOpen }
					text="Are you sure you want to remove this regular contract ?"
					onConfirm={ () => { this.removeRegularContract(this.state.removeRegularContractId); this.setState({ removeRegularContractId: 0, removeRegularContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removeRegularContractId: 0, removeRegularContractModalOpen: false }); } }
				/>

				<hr />

				<h3> <i className="fa fa-map-marker"></i> Punctual contracts </h3>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoice status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.punctualContracts.map((punctualContract) => {
						const invoice = punctualContract.PunctualContractInvoice;
						const startDate = punctualContract.PunctualOffers.length > 0 ?
							format(punctualContract.PunctualOffers.reduce(
								(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
								punctualContract.PunctualOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const endDate = punctualContract.PunctualOffers.length > 0 ?
							format(punctualContract.PunctualOffers.reduce(
								(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
								punctualContract.PunctualOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const schoolYear = this.state.schoolYears.find((s) => s.id === punctualContract.SchoolYearId);

						return (
							<tr key={ punctualContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ startDate }</td>
								<td>{ endDate }</td>
								<td>{ format(punctualContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ punctualContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{
										invoice && invoice.status === 'edited' ?
										<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
										invoice && invoice.status === 'sent' ?
										<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
										invoice && invoice.status === 'paid' ?
										<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment> :
										<React.Fragment><i style={{ color: '#CC0000' }} className="fa fa-ban"></i>{' '}<span>not edited</span></React.Fragment>
									}
								</td>
								<td className="text-right">
									{ punctualContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removePunctualContractId: punctualContract.id, removePunctualContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>{' '}Delete
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/punctual/' + punctualContract.id }>
										<i className="fa fa-search"></i>{' '}Detail
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removePunctualContractModalOpen }
					text="Are you sure you want to remove this punctual contract ?"
					onConfirm={ () => { this.removePunctualContract(this.state.removePunctualContractId); this.setState({ removePunctualContractId: 0, removePunctualContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removePunctualContractId: 0, removePunctualContractModalOpen: false }); } }
				/>

				<hr />

				<h3> <i className="fa fa-suitcase"></i> Holiday contracts </h3>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoice status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.holidayContracts.map((holidayContract) => {
						const invoice = holidayContract.HolidayContractInvoice;
						const startDate = holidayContract.HolidayOffers.length > 0 ?
							format(holidayContract.HolidayOffers.reduce(
								(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
								holidayContract.HolidayOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const endDate = holidayContract.HolidayOffers.length > 0 ?
							format(holidayContract.HolidayOffers.reduce(
								(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
								holidayContract.HolidayOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const schoolYear = this.state.schoolYears.find((s) => s.id === holidayContract.SchoolYearId);

						return (
							<tr key={ holidayContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ startDate }</td>
								<td>{ endDate }</td>
								<td>{ format(holidayContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ holidayContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{
										invoice && invoice.status === 'edited' ?
										<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
										invoice && invoice.status === 'sent' ?
										<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
										invoice && invoice.status === 'paid' ?
										<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment> :
										<React.Fragment><i style={{ color: '#CC0000' }} className="fa fa-ban"></i>{' '}<span>not edited</span></React.Fragment>
									}
								</td>
								<td className="text-right">
									{ holidayContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeHolidayContractId: holidayContract.id, removeHolidayContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>{' '}Delete
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/holiday/' + holidayContract.id }>
										<i className="fa fa-search"></i>{' '}Detail
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removeHolidayContractModalOpen }
					text="Are you sure you want to remove this holiday contract ?"
					onConfirm={ () => { this.removeHolidayContract(this.state.removeHolidayContractId); this.setState({ removeHolidayContractId: 0, removeHolidayContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removeHolidayContractId: 0, removeHolidayContractModalOpen: false }); } }
				/>
				</React.Fragment>
			) } />
			</React.Fragment>
		);
	}
};

ParentChildDetailContracts.propTypes = {
	child: PropTypes.object.isRequired
};

export default ParentChildDetailContracts;

import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Route, Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import RegularContractEnrolment from 'components/enrolment/regular_contract';
import PunctualContractEnrolment from 'components/enrolment/punctual_contract';
import HolidayContractEnrolment from 'components/enrolment/holiday_contract';

import { AuthContext } from 'contexts/auth';

const Enrolment = (props) => (
	<React.Fragment>
	<Route path={ props.match.url } exact render={ () => (
		!props.user ?
		<React.Fragment>
		<h2 className="text-center mb-4">
			If you already have an account, please first
		</h2>
		<div className="text-center">
			<Button tag={ Link } to={ '/user/signin' } color="primary" style={{ cursor: 'pointer' }}>
				<i className="fa fa-sign-in"></i> Signin
			</Button>
		</div>
		<h2 className="text-center mb-4">
			If you don't, choose a type of contract
		</h2>
		<div className="text-center mb-4">
			{props.user &&
				<Button tag={ Link } to={ props.match.url + '/regular' } color="primary" style={{ cursor: 'pointer' }}>
					<i className="fa fa-clock-o"></i> Regular contract
				</Button>}
			<Button tag={ Link } to={ props.match.url + '/holiday' } color="primary" style={{ cursor: 'pointer' }} className="ml-2">
				<i className="fa fa-suitcase"></i> Holiday contract
			</Button>
		</div>
		</React.Fragment> :
		<React.Fragment>
		<h2 className="text-center mb-4">
			Please choose a type of contract
		</h2>
		<div className="text-center">
			<Button tag={ Link } to={ props.match.url + '/regular' } color="primary" style={{ cursor: 'pointer' }}>
				<i className="fa fa-clock-o"></i> Regular contract
			</Button>
			<Button tag={ Link } to={ props.match.url + '/punctual' } color="primary" style={{ cursor: 'pointer' }} className="ml-2">
				<i className="fa fa-map-marker"></i> Punctual contract
			</Button>
			<Button tag={ Link } to={ props.match.url + '/holiday' } color="primary" style={{ cursor: 'pointer' }} className="ml-2">
				<i className="fa fa-suitcase"></i> Holiday contract
			</Button>
		</div>
		</React.Fragment>
	) } />
	<Route path={ props.match.url + '/regular' } component={ RegularContractEnrolment } />
	<Route path={ props.match.url + '/punctual' } component={ PunctualContractEnrolment } />
	<Route path={ props.match.url + '/holiday' } component={ HolidayContractEnrolment } />
	</React.Fragment>
);

Enrolment.propTypes = {
	user: PropTypes.object
};

export default subscribe(
	[ AuthContext ],
	(auth) => ({
		user: auth.user
	})
)(Enrolment);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { subscribe } from 'react-contextual';
import {
	Col, Form, FormGroup, Label, Input, Table
} from 'reactstrap';
import format from 'date-fns/format';
import getDay from 'date-fns/get_day';
import getYear from 'date-fns/get_year';
import compareAsc from 'date-fns/compare_asc';
import startOfWeek from 'date-fns/start_of_week';
import addDays from 'date-fns/add_days';

import { ConfigContext } from 'contexts/config';
import { timeStringToFloat, timeStringToHH } from 'services/time';
import { dayNames } from 'services/calendar';

import {
	fetchSchoolYears,
	fetchSchoolTimeSlotsOfSchoolYear,
	fetchHolidayTimeSlotsOfSchoolYear,
	fetchDaysOfSchoolYearBetweenDates,
	fetchRegularOffersOfSchoolYear,
	fetchRegularContractsOfSchoolYearAtDate,
	fetchPunctualContractsOfSchoolYearAtDate,
	fetchHolidayContractsOfSchoolYearAtDate
} from 'actions/school_year';
import { fetchAbsencesOfDay } from 'actions/day';

class AdminPresences extends React.Component {
	state = {
		dataReady: false,
		processingData: false,
		schoolYears: [],
		selectedSchoolYearId: 0,
		selectedSchoolYear: null,
		selectedSchoolYearSchoolTimeSlots: [],
		selectedSchoolYearSchoolTimeSlotsById: {},
		selectedSchoolYearHolidayTimeSlots: [],
		selectedSchoolYearHolidayTimeSlotsById: {},
		selectedSchoolYearRegularOffers: [],
		selectedSchoolYearRegularOffersById: {},
		selectedWeekStartDate: '',
		selectedWeekDays: [],
		selectedWeekDaysSlots: [],
		selectedWeekChildrenSlots: {}
	};

	updateSelectedWeekData = () => {
		const selectedWeekDaysSlots = this.state.selectedWeekDays.map((day) => {
			const weekday = (getDay(day.date) - 1 + 7) % 7;
			let retval;
			switch (day.type) {
				case 'school':
					retval = {
						day,
						slots: this.state.selectedSchoolYearSchoolTimeSlots
							.filter((s) => s.weekday === weekday)
							.map((slot) => ({ ...slot, nbRegistered: 0, nbAbsent: 0 }))
							.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime))
					};
					break;
				case 'holiday':
					retval = {
						day,
						slots: this.state.selectedSchoolYearHolidayTimeSlots
							.filter((slot) => slot.weekday === weekday)
							.map((slot) => ({ ...slot, nbRegistered: 0, nbAbsent: 0 }))
							.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime))
					};
					break;
				case 'closed':
					retval = {
						day,
						slots: []
					};
					break;
				default:
					retval = {
						day,
						slots: []
					};
					break;
			}
			return retval;
		});

		const selectedWeekChildrenSlots = {};

		Promise.all(
			selectedWeekDaysSlots.map((weekDaySlots) => {
				return new Promise((resolve, reject) => {
					const weekday = (getDay(weekDaySlots.day.date) - 1 + 7) % 7;
					switch (weekDaySlots.day.type) {
						case 'school': {
							Promise.all([
								fetchRegularContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, weekDaySlots.day.date),
								fetchPunctualContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, weekDaySlots.day.date),
								fetchAbsencesOfDay(weekDaySlots.day.id)
							])
							.then(([regularContracts, punctualContracts, absences]) => {
								const childrenSlots = regularContracts
									.map((rc) => {
										const offers = rc.RegularOffers.map((o) => this.state.selectedSchoolYearRegularOffersById[o.id]);
										const slots = [].concat(...offers.map((o) => o.SchoolTimeSlots.map((slot) => this.state.selectedSchoolYearSchoolTimeSlotsById[slot.id])));
										const offerSlots = slots.filter((s) => s.weekday === weekday).sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime));
										const daySlots = offerSlots
											.filter((offerSlot) => offerSlot.weekday === weekday)
											.map((offerSlot) => {
												const slot = weekDaySlots.slots.find((s) => s.id === offerSlot.id);
												slot.nbRegistered++;
												return slot;
											})
											.sort((a,b) => timeStringToFloat(a.startTime) - timeStringToFloat(b.startTime));
										return {
											child: rc.Child,
											slots: daySlots,
											abs: absences.filter((a) => a.ChildId === rc.Child.id)
										};
									})
									.filter((cs) => cs.slots.length > 0);

								childrenSlots.forEach((cs) => {
									if (!selectedWeekChildrenSlots.hasOwnProperty(cs.child.id)) {
										selectedWeekChildrenSlots[cs.child.id] = {
											child: cs.child,
											slotsByDay: {},
											absByDay: {}
										};
									}
									selectedWeekChildrenSlots[cs.child.id].slotsByDay[weekDaySlots.day.id] = cs.slots;
									selectedWeekChildrenSlots[cs.child.id].absByDay[weekDaySlots.day.id] = cs.abs;
								});
								
								punctualContracts.forEach((pc) => {
									const daySlots = pc.PunctualOffers
										.filter((o) => o.DayId === weekDaySlots.day.id)
										.map((o) => {
											const slot = weekDaySlots.slots.find((s) => s.id === o.SchoolTimeSlotId);
											slot.nbRegistered++;
											return slot;
										});
									if (selectedWeekChildrenSlots.hasOwnProperty(pc.Child.id)) {
										daySlots.forEach((s) => {
											if (!selectedWeekChildrenSlots[pc.Child.id].slotsByDay.hasOwnProperty(weekDaySlots.day.id)) {
												selectedWeekChildrenSlots[pc.Child.id].slotsByDay[weekDaySlots.day.id] = [];
											}
											if (!selectedWeekChildrenSlots[pc.Child.id].absByDay.hasOwnProperty(weekDaySlots.day.id)) {
												selectedWeekChildrenSlots[pc.Child.id].absByDay[weekDaySlots.day.id] = absences.filter((a) => a.ChildId === pc.Child.id);
											}
											selectedWeekChildrenSlots[pc.Child.id].slotsByDay[weekDaySlots.day.id].push(s);
										});
									}
								});

								weekDaySlots.slots.forEach((s) => {
									s.nbAbsent = absences.reduce((accu, abs) => abs.SchoolTimeSlotId === s.id ? accu+1 : accu, 0);
								});

								resolve();
							})
							.catch(() => reject());
							break;
						}
						case 'holiday': {
							Promise.all([
								fetchHolidayContractsOfSchoolYearAtDate(this.state.selectedSchoolYearId, weekDaySlots.day.date),
								fetchAbsencesOfDay(weekDaySlots.day.id)
							])
							.then(([holidayContracts, absences]) => {
								const childrenSlots = holidayContracts
									.map((hc) => {
										const daySlots = hc.HolidayOffers
											.filter((o) => o.DayId === weekDaySlots.day.id)
											.map((o) => {
												const slot = weekDaySlots.slots.find((s) => s.id === o.HolidayTimeSlotId);
												slot.nbRegistered++;
												return slot;
											});
										return {
											child: hc.Child,
											slots: daySlots,
											abs: absences.filter((a) => a.ChildId === hc.Child.id)
										}
									});
								
								childrenSlots.forEach((cs) => {
									if (!selectedWeekChildrenSlots.hasOwnProperty(cs.child.id)) {
										selectedWeekChildrenSlots[cs.child.id] = {
											child: cs.child,
											slotsByDay: {},
											absByDay: {}
										};
									}
									if (!selectedWeekChildrenSlots[cs.child.id].slotsByDay.hasOwnProperty(weekDaySlots.day.id)) {
										selectedWeekChildrenSlots[cs.child.id].slotsByDay[weekDaySlots.day.id] = cs.slots;
									} else {
										selectedWeekChildrenSlots[cs.child.id].slotsByDay[weekDaySlots.day.id] = [
											...selectedWeekChildrenSlots[cs.child.id].slotsByDay[weekDaySlots.day.id],
											...cs.slots
										];
									}
									selectedWeekChildrenSlots[cs.child.id].absByDay[weekDaySlots.day.id] = cs.abs;
								});

								weekDaySlots.slots.forEach((s) => {
									s.nbAbsent = absences.reduce((accu, abs) => abs.HolidayTimeSlotId === s.id ? accu+1 : accu, 0);
								});

								resolve();
							})
							.catch(() => reject());
							break;
						}
						case 'closed': {
							resolve();
							break;
						}
						default: {
							resolve();
							break;
						}
					}
				});
			})
		)
		.then(() => {
			this.setState({
				processingData: false,
				selectedWeekDaysSlots,
				selectedWeekChildrenSlots
			});
		});
	};

	handleSelectedSchoolYearChange = (e) => {
		const selectedSchoolYearId = parseInt(e.target.value, 10);
		const selectedSchoolYear = this.state.schoolYears.find((s) => s.id === selectedSchoolYearId);
		const selectedWeekStartDate = startOfWeek(selectedSchoolYear.startDate, { weekStartsOn: 1 });
		const selectedWeekEndDate = addDays(selectedWeekStartDate, 4);
		Promise.all([
			fetchSchoolTimeSlotsOfSchoolYear(selectedSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(selectedSchoolYearId),
			fetchRegularOffersOfSchoolYear(selectedSchoolYearId),
			fetchDaysOfSchoolYearBetweenDates(selectedSchoolYearId, selectedWeekStartDate, selectedWeekEndDate)
		])
		.then(([sts, hts, ro, weekDays]) => {
			const selectedSchoolYearSchoolTimeSlotsById = {};
			sts.forEach((s) => { selectedSchoolYearSchoolTimeSlotsById[s.id] = s; });
			const selectedSchoolYearHolidayTimeSlotsById = {};
			hts.forEach((s) => { selectedSchoolYearHolidayTimeSlotsById[s.id] = s; });
			const selectedSchoolYearRegularOffersById = {};
			ro.forEach((o) => { selectedSchoolYearRegularOffersById[o.id] = o; });
			this.setState({
				processingData: true,
				selectedSchoolYearId,
				selectedSchoolYear,
				selectedSchoolYearSchoolTimeSlots: sts,
				selectedSchoolYearSchoolTimeSlotsById,
				selectedSchoolYearHolidayTimeSlots: hts,
				selectedSchoolYearHolidayTimeSlotsById,
				selectedSchoolYearRegularOffers: ro,
				selectedSchoolYearRegularOffersById,
				selectedWeekStartDate: format(selectedWeekStartDate, 'YYYY-MM-DD'),
				selectedWeekDays: weekDays
			}, () => this.updateSelectedWeekData());
		});
	};

	handleSelectedDateChange = (e) => {
		const selectedWeekStartDate = startOfWeek(e.target.value, { weekStartsOn: 1 });
		const selectedWeekEndDate = addDays(selectedWeekStartDate, 4);
		fetchDaysOfSchoolYearBetweenDates(this.state.selectedSchoolYearId, selectedWeekStartDate, selectedWeekEndDate)
		.then((weekDays) => {
			this.setState({
				processingData: true,
				selectedWeekStartDate: format(selectedWeekStartDate, 'YYYY-MM-DD'),
				selectedWeekDays: weekDays
			}, () => this.updateSelectedWeekData());
		});
	};

	componentDidMount() {
		const selectedWeekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 });
		const selectedWeekEndDate = addDays(selectedWeekStartDate, 4);
		Promise.all([
			fetchSchoolYears(),
			fetchSchoolTimeSlotsOfSchoolYear(this.props.currentSchoolYearId),
			fetchHolidayTimeSlotsOfSchoolYear(this.props.currentSchoolYearId),
			fetchRegularOffersOfSchoolYear(this.props.currentSchoolYearId),
			fetchDaysOfSchoolYearBetweenDates(this.props.currentSchoolYearId, selectedWeekStartDate, selectedWeekEndDate)
		])
		.then(([schoolYears, sts, hts, ro, weekDays]) => {
			const selectedSchoolYearSchoolTimeSlotsById = {};
			sts.forEach((s) => { selectedSchoolYearSchoolTimeSlotsById[s.id] = s; });
			const selectedSchoolYearHolidayTimeSlotsById = {};
			hts.forEach((s) => { selectedSchoolYearHolidayTimeSlotsById[s.id] = s; });
			const selectedSchoolYearRegularOffersById = {};
			ro.forEach((o) => { selectedSchoolYearRegularOffersById[o.id] = o; });
			this.setState({
				dataReady: true,
				processingData: true,
				schoolYears,
				selectedSchoolYearId: this.props.currentSchoolYearId,
				selectedSchoolYear: schoolYears.find((s) => s.id === this.props.currentSchoolYearId),
				selectedSchoolYearSchoolTimeSlots: sts,
				selectedSchoolYearSchoolTimeSlotsById,
				selectedSchoolYearHolidayTimeSlots: hts,
				selectedSchoolYearHolidayTimeSlotsById,
				selectedSchoolYearRegularOffers: ro,
				selectedSchoolYearRegularOffersById,
				selectedWeekStartDate: format(selectedWeekStartDate, 'YYYY-MM-DD'),
				selectedWeekDays: weekDays
			}, () => this.updateSelectedWeekData());
		});
	}

	render() {
		const byBirthYear = {};
		Object.keys(this.state.selectedWeekChildrenSlots).forEach((childid) => {
			const childSlots = this.state.selectedWeekChildrenSlots[childid];
			const birthyear = getYear(childSlots.child.birthdate);
			if (!byBirthYear.hasOwnProperty(birthyear)) { byBirthYear[birthyear] = []; }
			byBirthYear[birthyear].push(childSlots);
		});
		Object.keys(byBirthYear).forEach((birthyear) => {
			byBirthYear[birthyear].sort((a,b) => compareAsc(a.child.birthdate, b.child.birthdate));
		});
		
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Form onSubmit={(e) => e.preventDefault()}>
				<FormGroup row>
					<Label for="schoolYear" xs={2} className="text-right">School year</Label>
					<Col xs={4}>
						<Input type="select" value={this.state.selectedSchoolYearId} onChange={this.handleSelectedSchoolYearChange}>
							{this.state.schoolYears.map((schoolYear) => (
								<option key={schoolYear.id} value={parseInt(schoolYear.id, 10)}>{schoolYear.title}</option>
							))}
						</Input>
					</Col>
					<Label for="day" xs={2} className="text-right">Date</Label>
					<Col xs={4}>
						<Input
							type="date" id="day" value={this.state.selectedWeekStartDate} onChange={this.handleSelectedDateChange}
							min={this.state.selectedSchoolYear.startDate} max={this.state.selectedSchoolYear.endDate}
						/>
					</Col>
				</FormGroup>
			</Form>
			{ this.state.processingData ?
			'Processing data...' :
			<Table striped hover size="sm" className="marginBottomProf">
				<thead>
					<tr>
						<th style={{ borderRight: '1px solid black'}}></th>
						{ this.state.selectedWeekDaysSlots.map((daySlots) => (
							<th key={daySlots.day.id} colSpan={daySlots.slots.length > 0 ? daySlots.slots.length : 1} className="text-center" style={{ borderRight: '1px solid black'}}>
								{dayNames[(getDay(daySlots.day.date) - 1 + 7) % 7]}
							</th>
						)) }
					</tr>
					<tr>
						<th style={{ borderRight: '1px solid black'}}></th>
						{ this.state.selectedWeekDaysSlots.map((daySlots) => (
							<th key={daySlots.day.id} colSpan={daySlots.slots.length > 0 ? daySlots.slots.length : 1} className="text-center" style={{ borderRight: '1px solid black'}}>
								{format(daySlots.day.date, 'DD-MM-YYYY')}
							</th>
						)) }
					</tr>
					<tr>
						<th style={{ borderRight: '1px solid black'}}></th>
						{ this.state.selectedWeekDaysSlots.map((daySlots) => (
							<React.Fragment key={daySlots.day.id}>
							{ daySlots.day.type === 'closed' ?
							<th className="text-center" style={{ borderRight: '1px solid black'}}>Closed</th> :
							daySlots.slots.map((slot, idx) => (
								<th key={slot.id} className="text-center" style={ idx === daySlots.slots.length - 1 ? { borderRight: '1px solid black', fontSize: '40%' } : { fontSize: '40%' } }>
									{timeStringToHH(slot.startTime)}-{timeStringToHH(slot.endTime)}
									<br />
									{slot.nbRegistered - slot.nbAbsent} / {slot.nbRegistered}
								</th>
							)) }
							</React.Fragment>
						)) }
					</tr>
				</thead>
				<tbody>
					{ Object.keys(byBirthYear).map((birthyear) => (
						<React.Fragment key={birthyear}>
						<tr><td
							className="text-center font-weight-bold"
							style={{backgroundColor: "#212529", color: "white"}}
						>
							{birthyear}
						</td></tr>
						<React.Fragment>
						{ byBirthYear[birthyear].map((childSlots) => (
							<tr key={childSlots.child.id}>
								<td style={{ borderRight: '1px solid black'}}>
									{childSlots.child.firstname} {childSlots.child.lastname}
									<Link to={ '/admin/children/' + childSlots.child.id + '/info' } className="ml-2"><i className="fa fa-external-link"></i></Link>
								</td>
								{ this.state.selectedWeekDaysSlots.map((daySlots) => (
									<React.Fragment key={daySlots.day.id}>
									{ daySlots.day.type === 'closed' ?
									<td className="text-center" style={{ borderRight: '1px solid black'}}>
										<div style={{backgroundColor: 'lightsteelblue', minHeight: '1.5em'}}></div>
									</td> :
									daySlots.slots.map((slot, idx) => {
										const isRegistered = childSlots.slotsByDay[daySlots.day.id] && childSlots.slotsByDay[daySlots.day.id].some((s) => s.id === slot.id);
										if (isRegistered) {
											const absence = daySlots.day.type === 'school' ?
												childSlots.absByDay[daySlots.day.id].find((a) => a.SchoolTimeSlotId === slot.id) :
												childSlots.absByDay[daySlots.day.id].find((a) => a.HolidayTimeSlotId === slot.id);
											if (absence) {
												return (
													<td key={slot.id} className="text-center" style={ idx === daySlots.slots.length - 1 ? { borderRight: '1px solid black'} : {} }>
														<div style={{backgroundColor: 'darkorange'}}><i style={{color: 'red'}} className="fa fa-ban"></i></div>
													</td>
												);
											} else {
												return (
													<td key={slot.id} className="text-center" style={ idx === daySlots.slots.length - 1 ? { borderRight: '1px solid black'} : {} }>
														<div style={{backgroundColor: 'lightgreen'}}><i style={{color: 'green'}} className="fa fa-check"></i></div>
													</td>
												);
											}
										} else {
											return (
												<td key={slot.id} style={ idx === daySlots.slots.length - 1 ? { borderRight: '1px solid black'} : {} }>
													<div style={{backgroundColor: 'lightsteelblue', minHeight: '1.5em'}}></div>
												</td>
											);
										}
									}) }
									</React.Fragment>
								)) }
							</tr>
						)) }
						</React.Fragment>
						</React.Fragment>
					)) }
				</tbody>
			</Table> }
			</React.Fragment>
		);
	}
};

AdminPresences.propTypes = {
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminPresences);

export const monthInvoiceFinder = function(d) {
    return function(i) {
        return i.year === d.getFullYear() && i.month === d.getMonth();
    }
}

export const paidOrSentMonthInvoiceFinder = function(d) {
    return function(i) {
        return (i.status === 'sent' || i.status === 'paid') && i.year === d.getFullYear() && i.month === d.getMonth();
    }
}
import React from 'react';
import PropTypes from 'prop-types';
import {
	Row, Col, Button, ButtonGroup,
	Table
} from 'reactstrap';
import { PulseLoader } from 'react-spinners';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfMonth from 'date-fns/start_of_month';
import isBefore from 'date-fns/is_before';
import addMonths from 'date-fns/add_months';
import format from 'date-fns/format';

import { monthNames } from 'services/calendar';
import { saveAs } from 'file-saver';

import { fetchSchoolYear, fetchRegularOffersOfSchoolYear } from 'actions/school_year';
import { downloadRegularContract } from 'actions/regular_contract';
import {paidOrSentMonthInvoiceFinder} from "../../../utils/month_invoice_finder";

class ParentChildDetailRegularContract extends React.Component {
	state = {
		dataReady: false,
		schoolYear: null,
		regularOffers: [],
		downloadingContract: false,
		downloadingInvoiceId: null
	};

	downloadContract = () => {
		this.setState({ downloadingContract: true });
		downloadRegularContract(this.props.regularContract.id)
		.then((blob) => {
			saveAs(blob, 'regular_contract.pdf');
			this.setState({ downloadingContract: false });
		});
	};

	downloadInvoice = (invoiceId) => {
		const invoice = this.props.regularContract.RegularContractInvoices.find((i) => i.id === invoiceId);
		if (invoice) {
			this.setState({ downloadingInvoiceId: invoice.id });
			this.props.downloadRegularContractInvoice(invoice.id, invoice.filename)
			.then(() => {
				this.setState({ downloadingInvoiceId: null });
			});
		}
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYear(this.props.regularContract.SchoolYearId),
			fetchRegularOffersOfSchoolYear(this.props.regularContract.SchoolYearId)
		])
		.then(([ schoolYear, regularOffers ]) => {
			this.setState({
				schoolYear,
				regularOffers,
				dataReady: true
			});
		});
	}
	
	render() {
		const contractInvoices = [];
		let d = startOfMonth(this.props.regularContract.startDate);
		const end = lastDayOfMonth(this.props.regularContract.endDate);
		while (isBefore(d, end)) {
			contractInvoices.push({
				year: d.getFullYear(),
				month: d.getMonth(),
				invoice: this.props.regularContract.RegularContractInvoices.find(paidOrSentMonthInvoiceFinder(d))
			});
			d = addMonths(d, 1);
		}

		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Row className="mb-2">
				<Col>
					<h4 className="text-right ml-auto">
						{ this.props.regularContract.status === 'valid' ?
							<i style={{ color: 'green'}} className="fa fa-check"></i> :
							<i style={{ color: 'red' }} className="fa fa-ban"></i>
						}
						{' '}Regular contract
						{' '}<i className="fa fa-clock-o"></i>
						<Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '13rem', height: '2rem' }} onClick={ this.downloadContract }>
							{ !this.state.downloadingContract && <React.Fragment>
								<i className="fa fa-download"></i>{' '}Download contract PDF
							</React.Fragment> }
							<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingContract} />
						</Button>
					</h4>
				</Col>
			</Row>
			<Table>
				<tbody>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Start date</th>
						<td>
							{ format(this.props.regularContract.startDate, 'DD-MM-YYYY') }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>End date</th>
						<td>
							{ format(this.props.regularContract.endDate, 'DD-MM-YYYY') }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent income</th>
						<td>
							{ (this.props.regularContract.parentIncome/100)+'€' }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Parent nb children</th>
						<td>
							{ this.props.regularContract.parentNbChildren }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>CAF rate</th>
						<td>
							{ this.props.regularContract.CAFrate ? <i style={{ color: 'green'}} className="fa fa-check"></i> : <i style={{ color: 'red'}} className="fa fa-ban"></i> }
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ width: '25%'}}>Payment method</th>
						<td>
							{ this.props.regularContract.paymentMethod }
						</td>
					</tr>
				</tbody>
			</Table>
			<ButtonGroup className="mb-4">
				{ this.state.regularOffers.map((regularOffer) => {
					const active = this.props.regularContract.RegularOffers.some((contractRegularOffer) => contractRegularOffer.id === regularOffer.id);
					const color = active ? 'success' : 'info';
					return (
						<Button key={ regularOffer.id } color={ color } active={ active } disabled >
							{ regularOffer.title }
						</Button>
					);
				}) }
			</ButtonGroup>
			<hr/>
			<h4 className="text-right ml-auto">
				Invoices{' '}<i className="fa fa-money"></i>
			</h4>
			<Table size="sm">
				<thead>
					<tr>
						<th>Month</th>
						<th>Amount</th>
						<th>Paid</th>
						<th>Due</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ contractInvoices.map((contractInvoice) => (
						<tr key={ contractInvoice.year + '-' + contractInvoice.month }>
							<th scope="row" style={{ width: '25%'}}>{ contractInvoice.year } { monthNames[contractInvoice.month] }</th>
							<td>{ contractInvoice.invoice ? (contractInvoice.invoice.amount/100)+'€' : '' }</td>
							<td>{ contractInvoice.invoice ? (contractInvoice.invoice.paidAmount/100)+'€' : '' }</td>
							<td>{ contractInvoice.invoice ?
								((contractInvoice.invoice.amount-contractInvoice.invoice.paidAmount)/100)+'€' : '' }
							</td>
							<td className="text-right">
								{ contractInvoice.invoice && contractInvoice.invoice.status === 'sent'
									&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-envelope"></i>{' '}Sent</Button>
								}
								{ contractInvoice.invoice && contractInvoice.invoice.status === 'paid'
									&& <Button color="success" size="sm" className="ml-2" disabled><i className="fa fa-check"></i>{' '}Paid</Button>
								}
								{ contractInvoice.invoice
									&& <Button color="info" size="sm" className="ml-2" style={{ cursor: 'pointer', width: '8rem' }}
										onClick={ () => this.downloadInvoice(contractInvoice.invoice.id) }
									>
										{ this.state.downloadingInvoiceId !== contractInvoice.invoice.id && <React.Fragment>
											<i className="fa fa-download"></i>{' '}Download
										</React.Fragment> }
										<PulseLoader size={10} color={'#ffffff'} loading={this.state.downloadingInvoiceId === contractInvoice.invoice.id} />
									</Button>
								}
							</td>
						</tr>
					)) }
				</tbody>
			</Table>
			</React.Fragment>
		);
	}
};

ParentChildDetailRegularContract.propTypes = {
	regularContract: PropTypes.object.isRequired,
	downloadRegularContractInvoice: PropTypes.func.isRequired
};

export default ParentChildDetailRegularContract;

import React from 'react';
import PropTypes from 'prop-types';
import { subscribe } from 'react-contextual';
import { Route, Link } from 'react-router-dom';
import {
	Row, Col, Table, Button, Badge,
	Form, FormGroup, Label, Input,
	UncontrolledTooltip,
	Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/last_day_of_month';
import startOfMonth from 'date-fns/start_of_month';
import addMonths from 'date-fns/add_months';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import compareDesc from 'date-fns/compare_desc';

import { ConfigContext } from 'contexts/config';

import { saveAs } from 'file-saver';

import ConfirmationModal from 'components/utils/confirmation_modal';

import AdminRegularContractDetail from 'components/admin/contracts/regular_contract';
import AdminPunctualContractDetail from 'components/admin/contracts/punctual_contract';
import AdminHolidayContractDetail from 'components/admin/contracts/holiday_contract';

import { fetchSchoolYears } from 'actions/school_year';
import {
	fetchRegularContractsOfChild,
	addRegularContractToChild,
	fetchPunctualContractsOfChild,
	addPunctualContractToChild,
	fetchHolidayContractsOfChild,
	addHolidayContractToChild
} from 'actions/child';
import {
	updateRegularContract,
	removeRegularContract,
	addRegularOfferToRegularContract,
	removeRegularOfferFromRegularContract,
	generateRegularContractInvoice
} from 'actions/regular_contract';
import {
	sendRegularContractInvoice,
	fetchRegularContractInvoice,
	removeRegularContractInvoice,
	updateRegularContractInvoice,
	addRegularContractInvoiceModification,
	removeRegularContractInvoiceModification
} from 'actions/regular_contract_invoice';
import {
	updatePunctualContract,
	removePunctualContract,
	addPunctualOfferToPunctualContract,
	removePunctualOfferFromPunctualContract,
	generatePunctualContractInvoice
} from 'actions/punctual_contract';
import {
	sendPunctualContractInvoice,
	fetchPunctualContractInvoice,
	removePunctualContractInvoice,
	updatePunctualContractInvoice,
	addPunctualContractInvoiceModification,
	removePunctualContractInvoiceModification
} from 'actions/punctual_contract_invoice';
import {
	updateHolidayContract,
	removeHolidayContract,
	addHolidayOfferToHolidayContract,
	removeHolidayOfferFromHolidayContract,
	generateHolidayContractInvoice
} from 'actions/holiday_contract';
import {
	sendHolidayContractInvoice,
	fetchHolidayContractInvoice,
	removeHolidayContractInvoice,
	updateHolidayContractInvoice,
	addHolidayContractInvoiceModification,
	removeHolidayContractInvoiceModification
} from 'actions/holiday_contract_invoice';
import {monthInvoiceFinder} from "../../../utils/month_invoice_finder";

class AdminChildDetailContracts extends React.Component {
	state = {
		dataReady: false,
		schoolYears: [],
		regularContracts: [],
		punctualContracts: [],
		holidayContracts: [],
		removeRegularContractId: 0,
		removeRegularContractModalOpen: false,
		removePunctualContractId: 0,
		removePunctualContractModalOpen: false,
		removeHolidayContractId: 0,
		removeHolidayContractModalOpen: false,
		newRegularContract: { SchoolYearId: this.props.currentSchoolYearId },
		showNewRegularContractForm: false,
		newPunctualContract: { SchoolYearId: this.props.currentSchoolYearId },
		showNewPunctualContractForm: false,
		newHolidayContract: { SchoolYearId: this.props.currentSchoolYearId },
		showNewHolidayContractForm: false,
		sendMailToParents: true
	};

	updateRegularContract = (regularContractId, changes) => {
		return updateRegularContract(regularContractId, changes)
		.then((regularContract) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc)
			}));
		});
	};
	addRegularOfferToRegularContract = (regularContractId, regularOfferId) => {
		return addRegularOfferToRegularContract(regularContractId, regularOfferId)
		.then((regularContract) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc)
			}));
		});
	};
	removeRegularOfferFromRegularContract = (regularContractId, regularOfferId) => {
		return removeRegularOfferFromRegularContract(regularContractId, regularOfferId)
		.then((regularContract) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => rc.id === regularContractId ? { ...rc, ...regularContract } : rc)
			}));
		});
	};
	generateRegularContractInvoice = (regularContractId, year, month) => {
		return generateRegularContractInvoice(regularContractId, year, month)
		.then((invoice) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.some((i) => i.id === invoice.id) ?
							rc.RegularContractInvoices.map((i) => i.id === invoice.id ? { ...i, ...invoice } : i) :
							[ ...rc.RegularContractInvoices, invoice ]
					} : rc;
				})
			}));
		});
	};
	downloadRegularContractInvoice = (invoiceId, filename) => {
		return fetchRegularContractInvoice(invoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	sendRegularContractInvoice = (regularContractId, invoiceId) => {
		return sendRegularContractInvoice(invoiceId)
		.then((invoice) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => i.id === invoiceId ? { ...i, ...invoice } : i)
					} : rc;
				})
			}));
		});
	};
	removeRegularContractInvoice = (regularContractId, invoiceId) => {
		return removeRegularContractInvoice(invoiceId)
		.then(() => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.filter((i) => i.id !== invoiceId)
					} : rc;
				})
			}));
		});
	};
	updateRegularContractInvoice = (regularContractId, invoiceId, changes) => {
		return updateRegularContractInvoice(invoiceId, changes)
		.then((invoice) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => i.id === invoiceId ? { ...i, ...invoice } : i)
					} : rc;
				})
			}));
		});
	};
	addRegularContractInvoiceModification = (regularContractId, invoiceId, modif) => {
		return addRegularContractInvoiceModification(invoiceId, modif)
		.then((invoiceModification) => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => {
							return i.id === invoiceId ? {
								...i,
								InvoiceModifications: [ ...i.InvoiceModifications, invoiceModification ]
							} : i;
						})
					} : rc;
				})
			}));
		});
	};
	removeRegularContractInvoiceModification = (regularContractId, invoiceId, modifId) => {
		return removeRegularContractInvoiceModification(invoiceId, modifId)
		.then(() => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.map((rc) => {
					return rc.id === regularContractId ? {
						...rc,
						RegularContractInvoices: rc.RegularContractInvoices.map((i) => {
							return i.id === invoiceId ? {
								...i,
								InvoiceModifications: i.InvoiceModifications.filter((m) => m.id !== modifId)
							} : i;
						})
					} : rc;
				})
			}));
		});
	};
	removeRegularContract = (regularContractId) => {
		return removeRegularContract(regularContractId, this.state.sendMailToParents)
		.then(() => {
			this.setState((prevState) => ({
				regularContracts: prevState.regularContracts.filter((c) => c.id !== regularContractId)
			}));
		});
	};
	handleNewRegularContractChange = (value, prop) => {
		this.setState((prevState) => ({
			newRegularContract: { ...prevState.newRegularContract, [prop]: value }
		}));
	};
	submitNewRegularContract = (e) => {
		e.preventDefault();
		const newRegularContract = { ...this.state.newRegularContract };
		if (this.state.regularContracts.length > 0) {
			newRegularContract.parentIncome = this.state.regularContracts[0].parentIncome;
			newRegularContract.parentNbChildren = this.state.regularContracts[0].parentNbChildren;
			newRegularContract.paymentMethod = this.state.regularContracts[0].paymentMethod;
			const lastOfSchoolYear = this.state.regularContracts.find((c) => c.SchoolYearId === newRegularContract.SchoolYearId);
			if (lastOfSchoolYear) {
				newRegularContract.startDate = startOfMonth(addMonths(lastOfSchoolYear.endDate, 1));
			}
		}
		return addRegularContractToChild(this.props.child.id, newRegularContract)
		.then((regularContract) => {
			this.setState((prevState) => ({
				regularContracts: [ ...prevState.regularContracts, regularContract ].sort((a, b) => compareDesc(a.startDate, b.startDate)),
				showNewRegularContractForm: false
			}));
		});
	};

	updatePunctualContract = (punctualContractId, changes) => {
		return updatePunctualContract(punctualContractId, changes)
		.then((punctualContract) => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => pc.id === punctualContractId ? { ...pc, ...punctualContract } : pc)
			}));
		});
	};
	addPunctualOfferToPunctualContract = (punctualContractId, punctualOffer) => {
		return addPunctualOfferToPunctualContract(punctualContractId, punctualOffer)
		.then((punctualContract) => {
			this.setState((prevState) => ({
				// TODO: recompute startDate & sort
				punctualContracts: prevState.punctualContracts.map((hc) => hc.id === punctualContractId ? { ...hc, ...punctualContract } : hc)
			}));
		});
	};
	removePunctualOfferFromPunctualContract = (punctualContractId, punctualOfferId) => {
		return removePunctualOfferFromPunctualContract(punctualContractId, punctualOfferId)
		.then((punctualContract) => {
			this.setState((prevState) => ({
				// TODO: recompute startDate & sort
				punctualContracts: prevState.punctualContracts.map((hc) => hc.id === punctualContractId ? { ...hc, ...punctualContract } : hc)
			}));
		});
	};
	generatePunctualContractInvoice = (punctualContractId) => {
		return generatePunctualContractInvoice(punctualContractId)
		.then((invoice) => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => {
					return pc.id === punctualContractId ? {
						...pc,
						PunctualContractInvoice: invoice
					} : pc;
				})
			}));
		});
	};
	downloadPunctualContractInvoice = (invoiceId, filename) => {
		return fetchPunctualContractInvoice(invoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	sendPunctualContractInvoice = (punctualContractId, invoiceId) => {
		return sendPunctualContractInvoice(invoiceId)
		.then((invoice) => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => {
					return pc.id === punctualContractId ? {
						...pc,
						PunctualContractInvoice: { ...pc.PunctualContractInvoice, ...invoice }
					} : pc;
				})
			}));
		});
	};
	removePunctualContractInvoice = (punctualContractId, invoiceId) => {
		return removePunctualContractInvoice(invoiceId)
		.then(() => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((rc) => {
					return rc.id === punctualContractId ? {
						...rc,
						PunctualContractInvoice: null
					} : rc;
				})
			}));
		});
	};
	updatePunctualContractInvoice = (punctualContractId, invoiceId, changes) => {
		return updatePunctualContractInvoice(invoiceId, changes)
		.then((invoice) => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => {
					return pc.id === punctualContractId ? {
						...pc,
						PunctualContractInvoice: { ...pc.PunctualContractInvoice, ...invoice }
					} : pc;
				})
			}));
		});
	};
	addPunctualContractInvoiceModification = (punctualContractId, invoiceId, modif) => {
		return addPunctualContractInvoiceModification(invoiceId, modif)
		.then((invoiceModification) => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => {
					return pc.id === punctualContractId ? {
						...pc,
						PunctualContractInvoice: {
							...pc.PunctualContractInvoice,
							InvoiceModifications: [ ...pc.PunctualContractInvoice.InvoiceModifications, invoiceModification ]
						}
					} : pc;
				})
			}));
		});
	};
	removePunctualContractInvoiceModification = (punctualContractId, invoiceId, modifId) => {
		return removePunctualContractInvoiceModification(invoiceId, modifId)
		.then(() => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.map((pc) => {
					return pc.id === punctualContractId ? {
						...pc,
						PunctualContractInvoice: {
							...pc.PunctualContractInvoice,
							InvoiceModifications: pc.PunctualContractInvoice.InvoiceModifications.filter((m) => m.id !== modifId)
						}
					} : pc;
				})
			}));
		});
	};
	removePunctualContract = (punctualContractId) => {
		return removePunctualContract(punctualContractId, this.state.sendMailToParents)
		.then(() => {
			this.setState((prevState) => ({
				punctualContracts: prevState.punctualContracts.filter((c) => c.id !== punctualContractId)
			}));
		});
	};
	handleNewPunctualContractChange = (value, prop) => {
		this.setState((prevState) => ({
			newPunctualContract: { ...prevState.newPunctualContract, [prop]: value }
		}));
	};
	submitNewPunctualContract = (e) => {
		e.preventDefault();
		const newPunctualContract = { ...this.state.newPunctualContract };
		if (this.state.punctualContracts.length > 0) {
			newPunctualContract.parentIncome = this.state.punctualContracts[0].parentIncome;
			newPunctualContract.parentNbChildren = this.state.punctualContracts[0].parentNbChildren;
			newPunctualContract.paymentMethod = this.state.punctualContracts[0].paymentMethod;
		}
		return addPunctualContractToChild(this.props.child.id, newPunctualContract)
		.then((punctualContract) => {
			punctualContract.startDate = this.state.schoolYears.find((s) => s.id === punctualContract.SchoolYearId).startDate;
			this.setState((prevState) => ({
				punctualContracts: [ ...prevState.punctualContracts, punctualContract ].sort((a, b) => compareDesc(a.startDate, b.startDate)),
				showNewPunctualContractForm: false
			}));
		});
	};

	updateHolidayContract = (holidayContractId, changes) => {
		return updateHolidayContract(holidayContractId, changes)
		.then((holidayContract) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc)
			}));
		});
	};
	addHolidayOfferToHolidayContract = (holidayContractId, holidayOffer) => {
		return addHolidayOfferToHolidayContract(holidayContractId, holidayOffer)
		.then((holidayContract) => {
			this.setState((prevState) => ({
				// TODO: recompute startDate & sort
				holidayContracts: prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc)
			}));
		});
	};
	removeHolidayOfferFromHolidayContract = (holidayContractId, holidayOfferId) => {
		return removeHolidayOfferFromHolidayContract(holidayContractId, holidayOfferId)
		.then((holidayContract) => {
			this.setState((prevState) => ({
				// TODO: recompute startDate & sort
				holidayContracts: prevState.holidayContracts.map((hc) => hc.id === holidayContractId ? { ...hc, ...holidayContract } : hc)
			}));
		});
	};
	generateHolidayContractInvoice = (holidayContractId) => {
		return generateHolidayContractInvoice(holidayContractId)
		.then((invoice) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: invoice
					} : hc;
				})
			}));
		});
	};
	downloadHolidayContractInvoice = (invoiceId, filename) => {
		return fetchHolidayContractInvoice(invoiceId)
		.then((blob) => {
			saveAs(blob, filename);
		});
	};
	sendHolidayContractInvoice = (holidayContractId, invoiceId) => {
		return sendHolidayContractInvoice(invoiceId)
		.then((invoice) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: { ...hc.HolidayContractInvoice, ...invoice }
					} : hc;
				})
			}));
		});
	};
	removeHolidayContractInvoice = (holidayContractId, invoiceId) => {
		return removeHolidayContractInvoice(invoiceId)
		.then(() => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: null
					} : hc;
				})
			}));
		});
	};
	updateHolidayContractInvoice = (holidayContractId, invoiceId, changes) => {
		return updateHolidayContractInvoice(invoiceId, changes)
		.then((invoice) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: { ...hc.HolidayContractInvoice, ...invoice }
					} : hc;
				})
			}));
		});
	};
	addHolidayContractInvoiceModification = (holidayContractId, invoiceId, modif) => {
		return addHolidayContractInvoiceModification(invoiceId, modif)
		.then((invoiceModification) => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: {
							...hc.HolidayContractInvoice,
							InvoiceModifications: [ ...hc.HolidayContractInvoice.InvoiceModifications, invoiceModification ]
						}
					} : hc;
				})
			}));
		});
	};
	removeHolidayContractInvoiceModification = (holidayContractId, invoiceId, modifId) => {
		return removeHolidayContractInvoiceModification(invoiceId, modifId)
		.then(() => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.map((hc) => {
					return hc.id === holidayContractId ? {
						...hc,
						HolidayContractInvoice: {
							...hc.HolidayContractInvoice,
							InvoiceModifications: hc.HolidayContractInvoice.InvoiceModifications.filter((m) => m.id !== modifId)
						}
					} : hc;
				})
			}));
		});
	};
	removeHolidayContract = (holidayContractId) => {
		return removeHolidayContract(holidayContractId, this.state.sendMailToParents)
		.then(() => {
			this.setState((prevState) => ({
				holidayContracts: prevState.holidayContracts.filter((c) => c.id !== holidayContractId)
			}));
		});
	};
	handleNewHolidayContractChange = (value, prop) => {
		this.setState((prevState) => ({
			newHolidayContract: { ...prevState.newHolidayContract, [prop]: value }
		}));
	};
	submitNewHolidayContract = (e) => {
		e.preventDefault();
		const newHolidayContract = { ...this.state.newHolidayContract };
		if (this.state.holidayContracts.length > 0) {
			newHolidayContract.parentIncome = this.state.holidayContracts[0].parentIncome;
			newHolidayContract.parentNbChildren = this.state.holidayContracts[0].parentNbChildren;
			newHolidayContract.paymentMethod = this.state.holidayContracts[0].paymentMethod;
		}
		return addHolidayContractToChild(this.props.child.id, newHolidayContract)
		.then((holidayContract) => {
			holidayContract.startDate = this.state.schoolYears.find((s) => s.id === holidayContract.SchoolYearId).startDate;
			this.setState((prevState) => ({
				holidayContracts: [ ...prevState.holidayContracts, holidayContract ].sort((a, b) => compareDesc(a.startDate, b.startDate)),
				showNewHolidayContractForm: false
			}));
		});
	};

	componentDidMount() {
		Promise.all([
			fetchSchoolYears(),
			fetchRegularContractsOfChild(this.props.child.id),
			fetchPunctualContractsOfChild(this.props.child.id),
			fetchHolidayContractsOfChild(this.props.child.id)
		])
		.then(([ schoolYears, regularContracts, punctualContracts, holidayContracts ]) => {
			const punctualContractsWithStartDate = punctualContracts.map((pc) => ({
				...pc,
				startDate: pc.PunctualOffers.length > 0 ?
					pc.PunctualOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						pc.PunctualOffers[0].Day.date
					) :
					new Date()
			}));
			const holidayContractsWithStartDate = holidayContracts.map((hc) => ({
				...hc,
				startDate: hc.HolidayOffers.length > 0 ?
					hc.HolidayOffers.reduce(
						(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
						hc.HolidayOffers[0].Day.date
					) :
					new Date()
			}));
			this.setState({
				schoolYears,
				regularContracts: regularContracts.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				punctualContracts: punctualContractsWithStartDate.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				holidayContracts: holidayContractsWithStartDate.sort((a, b) => compareDesc(a.startDate, b.startDate)),
				dataReady: true
			});
		});
	}

	render() {
		return (
			!this.state.dataReady ?
			'Loading...' :
			<React.Fragment>
			<Route path={ this.props.match.path + '/regular/:contractId' } render={ (props) => {
				const contract = this.state.regularContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<AdminRegularContractDetail
						regularContract={ contract }
						updateRegularContract={ (changes) => this.updateRegularContract(contract.id, changes) }
						generateRegularContractInvoice={ (year, month) => this.generateRegularContractInvoice(contract.id, year, month) }
						removeRegularContractInvoice={ (invoiceId) => this.removeRegularContractInvoice(contract.id, invoiceId) }
						downloadRegularContractInvoice={ (invoiceId, filename) => this.downloadRegularContractInvoice(invoiceId, filename) }
						sendRegularContractInvoice={ (invoiceId) => this.sendRegularContractInvoice(contract.id, invoiceId) }
						updateRegularContractInvoice={ (invoiceId, changes) => this.updateRegularContractInvoice(contract.id, invoiceId, changes) }
						addRegularOfferToRegularContract={ (regularOfferId) => this.addRegularOfferToRegularContract(contract.id, regularOfferId) }
						removeRegularOfferFromRegularContract={ (regularOfferId) => this.removeRegularOfferFromRegularContract(contract.id, regularOfferId) }
						addRegularContractInvoiceModification={ (invoiceId, modif) => this.addRegularContractInvoiceModification(contract.id, invoiceId, modif) }
						removeRegularContractInvoiceModification={ (invoiceId, modifId) => this.removeRegularContractInvoiceModification(contract.id, invoiceId, modifId) }
					/>
				);
			} } />
			<Route path={ this.props.match.path + '/punctual/:contractId' } render={ (props) => {
				const contract = this.state.punctualContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<AdminPunctualContractDetail
						punctualContract={ contract }
						updatePunctualContract={ (changes) => this.updatePunctualContract(contract.id, changes) }
						generatePunctualContractInvoice={ () => this.generatePunctualContractInvoice(contract.id) }
						removePunctualContractInvoice={ (invoiceId) => this.removePunctualContractInvoice(contract.id, invoiceId) }
						downloadPunctualContractInvoice={ (invoiceId, filename) => this.downloadPunctualContractInvoice(invoiceId, filename) }
						sendPunctualContractInvoice={ (invoiceId) => this.sendPunctualContractInvoice(contract.id, invoiceId) }
						updatePunctualContractInvoice={ (invoiceId, changes) => this.updatePunctualContractInvoice(contract.id, invoiceId, changes) }
						addOfferToContract={ (offer) => this.addPunctualOfferToPunctualContract(contract.id, offer) }
						removeOfferFromContract={ (offerId) => this.removePunctualOfferFromPunctualContract(contract.id, offerId) }
						addPunctualContractInvoiceModification={ (invoiceId, modif) => this.addPunctualContractInvoiceModification(contract.id, invoiceId, modif) }
						removePunctualContractInvoiceModification={ (invoiceId, modifId) => this.removePunctualContractInvoiceModification(contract.id, invoiceId, modifId) }
					/>
				);
			} } />
			<Route path={ this.props.match.path + '/holiday/:contractId' } render={ (props) => {
				const contract = this.state.holidayContracts.find((c) => c.id === parseInt(props.match.params.contractId, 10));
				return (
					<AdminHolidayContractDetail
						holidayContract={ contract }
						updateHolidayContract={ (changes) => this.updateHolidayContract(contract.id, changes) }
						generateHolidayContractInvoice={ () => this.generateHolidayContractInvoice(contract.id) }
						removeHolidayContractInvoice={ (invoiceId) => this.removeHolidayContractInvoice(contract.id, invoiceId) }
						downloadHolidayContractInvoice={ (invoiceId, filename) => this.downloadHolidayContractInvoice(invoiceId, filename) }
						sendHolidayContractInvoice={ (invoiceId) => this.sendHolidayContractInvoice(contract.id, invoiceId) }
						updateHolidayContractInvoice={ (invoiceId, changes) => this.updateHolidayContractInvoice(contract.id, invoiceId, changes) }
						addOfferToContract={ (offer) => this.addHolidayOfferToHolidayContract(contract.id, offer) }
						removeOfferFromContract={ (offerId) => this.removeHolidayOfferFromHolidayContract(contract.id, offerId) }
						addHolidayContractInvoiceModification={ (invoiceId, modif) => this.addHolidayContractInvoiceModification(contract.id, invoiceId, modif) }
						removeHolidayContractInvoiceModification={ (invoiceId, modifId) => this.removeHolidayContractInvoiceModification(contract.id, invoiceId, modifId) }
					/>
				);
			} } />
			<Route path={ this.props.match.url } exact render={ () => (
				<React.Fragment>
				<Row>
					<Col>
						<h3> <i className="fa fa-clock-o"></i> Regular contracts </h3>
					</Col>
					<Col className="text-right">
						<Button size="sm" color="success" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ showNewRegularContractForm: true }) }>
							<i className="fa fa-plus"></i>{' '}Add
						</Button>
					</Col>
				</Row>
				<Modal isOpen={ this.state.showNewRegularContractForm }>
					<ModalHeader>New regular contract</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup row>
								<Label for="schoolYear" sm={4}>School year</Label>
								<Col sm={8}>
									<Input type="select" value={ this.state.newRegularContract.SchoolYearId } onChange={ (e) => this.handleNewRegularContractChange(parseInt(e.target.value, 10), 'SchoolYearId') }>
										{ this.state.schoolYears.map((schoolYear) => (
											<option key={ schoolYear.id } value={ parseInt(schoolYear.id, 10) }>{ schoolYear.title }</option>
										)) }
									</Input>
								</Col>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={ () => this.setState({ showNewRegularContractForm: false }) }>Cancel</Button>
						<Button color="primary" onClick={ this.submitNewRegularContract }>Add</Button>
					</ModalFooter>
				</Modal>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoices status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.regularContracts.map((regularContract) => {
						const contractInvoices = [];
						let d = startOfMonth(regularContract.startDate);
						const end = lastDayOfMonth(regularContract.endDate);
						while (isBefore(d, end)) {
							contractInvoices.push({
								year: d.getFullYear(),
								month: d.getMonth(),
								invoice: regularContract.RegularContractInvoices.find(monthInvoiceFinder(d))
							});
							d = addMonths(d, 1);
						}
						const schoolYear = this.state.schoolYears.find((s) => s.id === regularContract.SchoolYearId);

						return (
							<tr key={ regularContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ format(regularContract.startDate, 'DD-MM-YYYY') }</td>
								<td>{ format(regularContract.endDate, 'DD-MM-YYYY') }</td>
								<td>{ format(regularContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ regularContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{ contractInvoices.map((contractInvoice, idx) => {
										const color =
											contractInvoice.invoice && contractInvoice.invoice.status === 'edited' ? '#FF8000' :
											contractInvoice.invoice && contractInvoice.invoice.status === 'sent' ? '#DDDD00' :
											contractInvoice.invoice && contractInvoice.invoice.status === 'paid' ? '#66CC00' :
											'#CC0000';
										const invoiceid = 'invoice_'+regularContract.id+'_'+contractInvoice.year+'_'+contractInvoice.month;
										return (
											<span key={ idx }>
												<Badge style={{ backgroundColor: color }} id={ invoiceid }>
													{ contractInvoice.month + 1 }
												</Badge>
												<UncontrolledTooltip placement="top" delay={{ show: 0, hide: 100 }} autohide={ false } target={ invoiceid }>
													{ contractInvoice.invoice && contractInvoice.invoice.status === 'edited'
														&& <Button color="warning" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
															onClick={ () => this.sendRegularContractInvoice(regularContract.id, contractInvoice.invoice.id) }
														>
															<i className="fa fa-envelope-o"></i>
														</Button>
													}
													{ contractInvoice.invoice && contractInvoice.invoice.status === 'sent'
														&& <Button color="success" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
															onClick={ () => this.updateRegularContractInvoice(regularContract.id, contractInvoice.invoice.id, { status: 'paid', paidAmount: contractInvoice.invoice.amount }) }
														>
															<i className="fa fa-check"></i> ({ (contractInvoice.invoice.amount - contractInvoice.invoice.paidAmount) / 100 }€)
														</Button>
													}
													{ contractInvoice.invoice && contractInvoice.invoice.status === 'paid'
														&& <Button color="danger" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
															onClick={ () => this.updateRegularContractInvoice(regularContract.id, contractInvoice.invoice.id, { status: 'sent', paidAmount: 0 }) }
														>
															<i className="fa fa-times"></i>
														</Button>
													}
													{ contractInvoice.invoice
														&& <Button color="info" size="sm" className="mr-2" style={{ cursor: 'pointer' }}
															onClick={ () => this.downloadRegularContractInvoice(contractInvoice.invoice.id, contractInvoice.invoice.filename) }
														>
															<i className="fa fa-download"></i>
														</Button>
													}
													<Button color="primary" size="sm" style={{ cursor: 'pointer' }}
														onClick={ () => this.generateRegularContractInvoice(regularContract.id, contractInvoice.year, contractInvoice.month) }
													>
														<i className="fa fa-file-text"></i>
													</Button>
												</UncontrolledTooltip>
											</span>
										);
									}) }
								</td>
								<td className="text-right">
									{ regularContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeRegularContractId: regularContract.id, removeRegularContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/regular/' + regularContract.id }>
										<i className="fa fa-search"></i>
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removeRegularContractModalOpen }
					onConfirm={ () => { this.removeRegularContract(this.state.removeRegularContractId); this.setState({ removeRegularContractId: 0, removeRegularContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removeRegularContractId: 0, removeRegularContractModalOpen: false }); } }
				>
					<p>Are you sure you want to remove this regular contract ?</p>
					<Form>
						<FormGroup row>
							<Col sm={1} style={{ textAlign: 'right' }}>
								<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="sendmail" checked={ this.state.sendMailToParents } onChange={ (e) => this.setState({ sendMailToParents: e.target.checked }) } />
							</Col>
							<Label for="sendmail" sm={11}>Send email to the parents</Label>
						</FormGroup>
					</Form>
				</ConfirmationModal>

				<hr />

				<Row>
					<Col>
						<h3> <i className="fa fa-map-marker"></i> Punctual contracts </h3>
					</Col>
					<Col className="text-right">
						<Button size="sm" color="success" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ showNewPunctualContractForm: true }) }>
							<i className="fa fa-plus"></i>{' '}Add
						</Button>
					</Col>
				</Row>
				<Modal isOpen={ this.state.showNewPunctualContractForm }>
					<ModalHeader>New punctual contract</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup row>
								<Label for="schoolYear" sm={4}>School year</Label>
								<Col sm={8}>
									<Input type="select" value={ this.state.newPunctualContract.SchoolYearId } onChange={ (e) => this.handleNewPunctualContractChange(parseInt(e.target.value, 10), 'SchoolYearId') }>
										{ this.state.schoolYears.map((schoolYear) => (
											<option key={ schoolYear.id } value={ parseInt(schoolYear.id, 10) }>{ schoolYear.title }</option>
										)) }
									</Input>
								</Col>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={ () => this.setState({ showNewPunctualContractForm: false }) }>Cancel</Button>
						<Button color="primary" onClick={ this.submitNewPunctualContract }>Add</Button>
					</ModalFooter>
				</Modal>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoice status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.punctualContracts.map((punctualContract) => {
						const invoice = punctualContract.PunctualContractInvoice;
						const startDate = punctualContract.PunctualOffers.length > 0 ?
							format(punctualContract.PunctualOffers.reduce(
								(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
								punctualContract.PunctualOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const endDate = punctualContract.PunctualOffers.length > 0 ?
							format(punctualContract.PunctualOffers.reduce(
								(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
								punctualContract.PunctualOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const schoolYear = this.state.schoolYears.find((s) => s.id === punctualContract.SchoolYearId);

						return (
							<tr key={ punctualContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ startDate }</td>
								<td>{ endDate }</td>
								<td>{ format(punctualContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ punctualContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{
										invoice && invoice.status === 'edited' ?
										<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
										invoice && invoice.status === 'sent' ?
										<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
										invoice && invoice.status === 'paid' ?
										<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment> :
										<React.Fragment><i style={{ color: '#CC0000' }} className="fa fa-ban"></i>{' '}<span>not edited</span></React.Fragment>
									}
								</td>
								<td className="text-right">
									{ punctualContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removePunctualContractId: punctualContract.id, removePunctualContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/punctual/' + punctualContract.id }>
										<i className="fa fa-search"></i>
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removePunctualContractModalOpen }
					onConfirm={ () => { this.removePunctualContract(this.state.removePunctualContractId); this.setState({ removePunctualContractId: 0, removePunctualContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removePunctualContractId: 0, removePunctualContractModalOpen: false }); } }
				>
					<p>Are you sure you want to remove this punctual contract ?</p>
					<Form>
						<FormGroup row>
							<Col sm={1} style={{ textAlign: 'right' }}>
								<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="sendmail" checked={ this.state.sendMailToParents } onChange={ (e) => this.setState({ sendMailToParents: e.target.checked }) } />
							</Col>
							<Label for="sendmail" sm={11}>Send email to the parents</Label>
						</FormGroup>
					</Form>
				</ConfirmationModal>

				<hr />

				<Row>
					<Col>
						<h3> <i className="fa fa-suitcase"></i> Holiday contracts </h3>
					</Col>
					<Col className="text-right">
						<Button size="sm" color="success" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ showNewHolidayContractForm: true }) }>
							<i className="fa fa-plus"></i>{' '}Add
						</Button>
					</Col>
				</Row>
				<Modal isOpen={ this.state.showNewHolidayContractForm }>
					<ModalHeader>New holiday contract</ModalHeader>
					<ModalBody>
						<Form>
							<FormGroup row>
								<Label for="schoolYear" sm={4}>School year</Label>
								<Col sm={8}>
									<Input type="select" value={ this.state.newHolidayContract.SchoolYearId } onChange={ (e) => this.handleNewHolidayContractChange(parseInt(e.target.value, 10), 'SchoolYearId') }>
										{ this.state.schoolYears.map((schoolYear) => (
											<option key={ schoolYear.id } value={ parseInt(schoolYear.id, 10) }>{ schoolYear.title }</option>
										)) }
									</Input>
								</Col>
							</FormGroup>
						</Form>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={ () => this.setState({ showNewHolidayContractForm: false }) }>Cancel</Button>
						<Button color="primary" onClick={ this.submitNewHolidayContract }>Add</Button>
					</ModalFooter>
				</Modal>
				<Table striped hover size="sm">
					<thead>
						<tr>
							<th>School year</th>
							<th>Start date</th>
							<th>End date</th>
							<th>Demand date</th>
							<th>Contract status</th>
							<th>Invoice status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
					{ this.state.holidayContracts.map((holidayContract) => {
						const invoice = holidayContract.HolidayContractInvoice;
						const startDate = holidayContract.HolidayOffers.length > 0 ?
							format(holidayContract.HolidayOffers.reduce(
								(start, offer) => isBefore(offer.Day.date, start) ? offer.Day.date : start,
								holidayContract.HolidayOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const endDate = holidayContract.HolidayOffers.length > 0 ?
							format(holidayContract.HolidayOffers.reduce(
								(end, offer) => isAfter(offer.Day.date, end) ? offer.Day.date : end,
								holidayContract.HolidayOffers[0].Day.date
							), 'DD-MM-YYYY') :
							'???';
						const schoolYear = this.state.schoolYears.find((s) => s.id === holidayContract.SchoolYearId);

						return (
							<tr key={ holidayContract.id }>
								<td>{ schoolYear.title }</td>
								<td>{ startDate }</td>
								<td>{ endDate }</td>
								<td>{ format(holidayContract.createdAt, 'DD-MM-YYYY') }</td>
								<td>
									{ holidayContract.status === 'valid' ?
										<i style={{ color: 'green' }} className="fa fa-check"></i> :
										<i style={{ color: 'red' }} className="fa fa-ban"></i>
									}
								</td>
								<td>
									{
										invoice && invoice.status === 'edited' ?
										<React.Fragment><i style={{ color: '#FF8000' }} className="fa fa-file-text"></i>{' '}<span>edited</span></React.Fragment> :
										invoice && invoice.status === 'sent' ?
										<React.Fragment><i style={{ color: '#CCCC00' }} className="fa fa-envelope"></i>{' '}<span>sent</span></React.Fragment> :
										invoice && invoice.status === 'paid' ?
										<React.Fragment><i style={{ color: '#66CC00' }} className="fa fa-money"></i>{' '}<span>paid</span></React.Fragment> :
										<React.Fragment><i style={{ color: '#CC0000' }} className="fa fa-ban"></i>{' '}<span>not edited</span></React.Fragment>
									}
								</td>
								<td className="text-right">
									{ holidayContract.status === 'demand' &&
									<Button size="sm" color="danger" className="ml-2" style={{ cursor: 'pointer' }} onClick={ () => this.setState({ removeHolidayContractId: holidayContract.id, removeHolidayContractModalOpen: true }) }>
										<i className="fa fa-trash"></i>
									</Button> }
									<Button size="sm" color="info" className="ml-2" tag={ Link } to={ this.props.match.url + '/holiday/' + holidayContract.id }>
										<i className="fa fa-search"></i>
									</Button>
								</td>
							</tr>
						);
					}) }
					</tbody>
				</Table>
				<ConfirmationModal
					isOpen={ this.state.removeHolidayContractModalOpen }
					onConfirm={ () => { this.removeHolidayContract(this.state.removeHolidayContractId); this.setState({ removeHolidayContractId: 0, removeHolidayContractModalOpen: false }); } }
					onDismiss={ () => { this.setState({ removeHolidayContractId: 0, removeHolidayContractModalOpen: false }); } }
				>
					<p>Are you sure you want to remove this holiday contract ?</p>
					<Form>
						<FormGroup row>
							<Col sm={1} style={{ textAlign: 'right' }}>
								<Input type="checkbox" style={{ marginLeft: '0.1rem'}} id="sendmail" checked={ this.state.sendMailToParents } onChange={ (e) => this.setState({ sendMailToParents: e.target.checked }) } />
							</Col>
							<Label for="sendmail" sm={11}>Send email to the parents</Label>
						</FormGroup>
					</Form>
				</ConfirmationModal>
				</React.Fragment>
			) } />
			</React.Fragment>
		);
	}
};

AdminChildDetailContracts.propTypes = {
	child: PropTypes.object.isRequired,
	currentSchoolYearId: PropTypes.number.isRequired
};

export default subscribe(
	[ ConfigContext ],
	(config) => ({
		currentSchoolYearId: config.getConfigValue('currentSchoolYearId')
	})
)(AdminChildDetailContracts);
